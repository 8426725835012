:root {
  --green: #01695b;
  --black: #2b1307;
  --white: #fff7df;
  --dark-green: #1b241d;
  --brown: #7e4210;
  --pink: #975653;
  --red: #580205;
  --cream: #efba8e;
  --light-brown: #e89c60;
  --medium-brown: #cb7226;
  --grey: gray;
  --screenwidth: 0;
  --screenheight: 0;
}
/*******************************************************************/
/* Navigation */

.navigation {
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: var(--dark-green);
  color: var(--white);
  height: calc(var(--screenheight) * 0.115px);
  padding: calc(var(--screenheight) * 0.019px);
  min-width: 50%;
  width: 100%;
}
.web-title {
  font-size: calc(var(--screenheight) * 0.04px);
}
.degen {
  margin-left: calc(var(--screenwidth) * 0.02px);
}
.num-players-input {
  border: transparent;
  color: var(--white);
  background-color: transparent;
  width: calc(var(--screenwidth) * 0.035px);
  margin-left: calc(var(--screenwidth) * 0.0069px);
}

/*******************************************************************/
/* Body */

body {
  background-color: #01695b;
}

.wrapper {
  vertical-align: middle;
  margin-top: calc(var(--screenheight) * 0.15px);
  width: calc(var(--screenwidth) * 1px);
  height: calc(var(--screenheight) * 1px * 0.81);
  min-width: calc(var(--screenwidth) * 1px);
  min-height: calc(var(--screenheight) * 1px * 0.81);
}

/*******************************************************************/
/* Matrix */

.matrix {
  height: calc(var(--screenheight) * 1px * 0.81);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: calc(var(--screenwidth) * 1px * 0.005);
  margin-left: calc(var(--screenwidth) * 1px * 0.01);
}

.matrix-cell {
  width: calc(var(--screenheight) * 1px * 0.058);
  height: calc(var(--screenheight) * 1px * 0.058);
  text-align: center;
  vertical-align: middle;
  border: transparent;
  font-size: calc(var(--screenheight) * 1px * 0.015);
  border-radius: calc(var(--screenheight) * 1px * 0.008);
  margin-right: calc(var(--screenwidth) * 1px * 0.003);
}

.matrix-cell:hover {
  width: calc(var(--screenheight) * 1px * 0.054);
  height: calc(var(--screenheight) * 1px * 0.054);
  text-align: center;
  vertical-align: middle;
  background-color: var(--cream);
  color: var(--black);
  border: transparent;
  font-size: calc(var(--screenheight) * 1px * 0.015);
  border-radius: calc(var(--screenheight) * 1px * 0.008);
  margin-right: calc(var(--screenwidth) * 1px * 0.005);
}

.hand-F {
  background-color: var(--white);
  color: var(--black);
}

.hand-R {
  background-color: var(--red);
  color: var(--white);
}

.hand-C {
  background-color: var(--medium-brown);
  color: var(--white);
}

.hand-B {
  background-color: var(--dark-green);
  color: var(--white);
}

/*******************************************************************/

.actions {
  height: calc(var(--screenheight) * 1px * 0.81);
  width: calc(var(--screenwidth) * 1px * 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.action-button-wrapper {
  height: 85%;
  background-color: var(--dark-green);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: calc(var(--screenheight) * 1px * 0.01);
  padding-bottom: calc(var(--screenheight) * 1px * 0.01);
  padding-left: calc(var(--screenheight) * 1px * 0.02);
  padding-right: calc(var(--screenheight) * 1px * 0.02);
  border-radius: calc(var(--screenheight) * 1px * 0.02);
}

.chart-title {
  text-align: center;
  width: calc(var(--screenwidth) * 1px * 0.2);
  height: calc(var(--screenheight) * 1px * 0.1);
  border: transparent;
  background-color: var(--medium-brown);
  color: var(--white);
  font-size: calc(var(--screenheight) * 1px * 0.025);
  border-radius: calc(var(--screenheight) * 1px * 0.01);
  margin-bottom: calc(var(--screenheight) * 1px * 0.015);
}
.actions-row {
  height: 8%;
  display: flex;
  justify-content: space-between;
}
.position {
  width: 40%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  border: transparent;
  font-size: calc(var(--screenheight) * 1px * 0.015);
  border-radius: calc(var(--screenheight) * 1px * 0.01);
}
.action {
  width: 16.5%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  border: transparent;
  font-size: calc(var(--screenheight) * 1px * 0.015);
  border-radius: calc(var(--screenheight) * 1px * 0.01);
  margin-left: calc(var(--screenwidth) * 1px * 0.004);
}
.position-off {
  background-color: var(--white);
  color: var(--black);
}
.position-off:hover {
  background-color: var(--cream);
  color: var(--black);
}
.position-on {
  background-color: var(--medium-brown);
  color: var(--white);
}
.position-dead {
  background-color: var(--grey);
  color: var(--white);
}
.action-off {
  background-color: var(--white);
  color: var(--black);
}
.action-off:hover {
  background-color: var(--cream);
  color: var(--black);
}
.action-on {
  background-color: var(--medium-brown);
  color: var(--white);
}
.action-dead {
  background-color: var(--grey);
  color: var(--white);
}

/*******************************************************************/

.right-panel {
  height: calc(var(--screenheight) * 1px * 0.81);
  width: calc(var(--screenwidth) * 1px * 0.18);
}

.curr_hand {
  vertical-align: middle;
  width: 100%;
  height: calc(var(--screenheight) * 1px * 0.35);
  text-align: center;
  vertical-align: middle;
  border: transparent;
  font-size: calc(var(--screenheight) * 1px * 0.05);
  border-radius: calc(var(--screenheight) * 1px * 0.0162);
}
.suggestion {
  font-size: calc(var(--screenheight) * 1px * 0.025);
}

/*******************************************************************/

.legend-container {
  width: calc(var(--screenwidth) * 1px * 0.18);
  background-color: var(--dark-green);
  border-radius: calc(var(--screenheight) * 1px * 0.0162);
  height: calc(var(--screenheight) * 1px * 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.legend-box {
  height: 80%;
  width: 80%;
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: calc(var(--screenheight) * 1px * 0.02);
}
.legend-color {
  height: calc(var(--screenheight) * 1px * 0.03);
  width: calc(var(--screenheight) * 1px * 0.03);
  border-radius: calc(var(--screenheight) * 1px * 0.0122);
  margin-right: calc(var(--screenheight) * 1px * 0.0122);
  margin-bottom: calc(var(--screenheight) * 1px * 0.005);
  border: 1.6px solid var(--white);
}
.l-0 {
  background-color: var(--red);
}
.l-1 {
  background-color: var(--medium-brown);
}
.l-2 {
  background-color: var(--dark-green);
}
.l-3 {
  background-color: var(--white);
}

/*******************************************************************/

.hand-history {
  border-radius: calc(var(--screenheight) * 1px * 0.0162);
  height: calc(var(--screenheight) * 1px * 0.23);
  color: var(--white);
  background-color: var(--dark-green);
  padding: calc(var(--screenheight) * 1px * 0.0162);
}

.block {
  height: calc(var(--screenheight) * 1px * 0.18);
  padding-left: calc(var(--screenheight) * 1px * 0.0202);
  padding-right: calc(var(--screenheight) * 1px * 0.0202);
  padding-bottom: calc(var(--screenheight) * 1px * 0.0202);
  padding-top: calc(var(--screenheight) * 1px * 0.0068);
  border-radius: calc(var(--screenheight) * 1px * 0.0202);
  overflow-y: scroll;
  font-size: calc(var(--screenheight) * 1px * 0.018);
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: calc(var(--screenwidth) * 1px * 0.005);
}

::-webkit-scrollbar-thumb {
  border-radius: calc(var(--screenwidth) * 1px * 0.0054);
  background-color: rgba(0, 0, 0, 0.5);
}

/*******************************************************************/

.selected {
  border: calc(var(--screenheight) * 1px * 0.004) solid var(--white);
}
.page {
  color: var(--white);
  background-color: transparent;
  border: transparent;
  font-size: calc(var(--screenheight) * 1px * 0.0162);
  margin-right: calc(var(--screenheight) * 1px * 0.02);
}

.page:hover {
  color: var(--grey);
}

.tutorial {
  color: var(--white);
  background-color: var(--medium-brown);
  border: transparent;
  font-size: calc(var(--screenheight) * 1px * 0.0203);
  margin-right: calc(var(--screenheight) * 1px * 0.0217);
  padding-left: calc(var(--screenheight) * 1px * 0.0244);
  padding-right: calc(var(--screenheight) * 1px * 0.0244);
  padding-top: calc(var(--screenheight) * 1px * 0.0135);
  padding-bottom: calc(var(--screenheight) * 1px * 0.0135);
  border-radius: calc(var(--screenheight) * 1px * 0.0054);
}

.tutorial:hover {
  background-color: var(--cream);
}
/*******************************************************************/
/* Range Constructor Page */

.left-panel {
  height: calc(var(--screenheight) * 1px * 0.81);
}

.file-tree-container {
  height: 85%;
  background-origin: padding-box;
  width: calc(var(--screenwidth) * 1px * 0.2);
  background-color: var(--dark-green);
  border-radius: calc(var(--screenheight) * 1px * 0.02);
}

.file-tree {
  width: 100%;
  height: 90%;
  margin-right: calc(var(--screenheight) * 1px * 0.03);
  margin-top: calc(var(--screenheight) * 1px * 0.03);
  margin-bottom: calc(var(--screenheight) * 1px * 0.03);
  overflow-y: auto;
}
.tree-item-component {
  background-color: transparent;
  border: transparent;
  margin-bottom: calc(var(--screenheight) * 1px * 0.02);
  color: var(--white);
  font-size: calc(var(--screenheight) * 1px * 0.023);
}
.tree-item-component:hover {
  border: transparent;
  margin-bottom: calc(var(--screenheight) * 1px * 0.02);
  color: var(--cream);
  font-size: calc(var(--screenheight) * 1px * 0.023);
}

.tree-container-component {
  text-align: left;
  width: calc(var(--screenwidth) * 1px * 0.15);
  color: var(--white);
  border: transparent;
  background-color: transparent;
  margin-bottom: calc(var(--screenheight) * 1px * 0.02);
  font-size: calc(var(--screenheight) * 1px * 0.023);
}

.margin-tree {
  margin-left: calc(var(--screenheight) * 1px * 0.03);
}

.tree-item-component-disable {
  color: var(--medium-brown);
}

/*******************************************************************/

.edit-button {
  font-size: calc(var(--screenheight) * 1px * 0.02);
  border: transparent;
  background-color: var(--white);
  width: 100%;
  height: calc(var(--screenheight) * 1px * 0.055);
  border-radius: calc(var(--screenheight) * 1px * 0.01);
  margin-top: calc(var(--screenheight) * 1px * 0.012);
}

.legend-container-new {
  width: calc(var(--screenwidth) * 1px * 0.18);
  background-color: var(--dark-green);
  border-radius: calc(var(--screenheight) * 1px * 0.0162);
  height: calc(var(--screenheight) * 1px * 0.38);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}
.legend-box-new {
  height: calc(var(--screenheight) * 1px * 0.33);
  width: 80%;
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: calc(var(--screenheight) * 1px * 0.02);
}

.select {
  font-size: calc(var(--screenheight) * 1px * 0.04);
  margin-bottom: calc(var(--screenheight) * 1px * 0.02);
}

/*******************************************************************/
/* Reset Buttons */
.reset-container {
  width: 100%;
  background-color: var(--dark-green);
  border-radius: calc(var(--screenheight) * 1px * 0.0162);
  height: calc(var(--screenheight) * 1px * 0.25);
  align-items: center;
  margin-bottom: calc(var(--screenheight) * 1px * 0.018);
}

.reset-inner-container {
  width: 80%;
  margin-bottom: calc(var(--screenheight) * 1px * 0.015);
  margin-top: calc(var(--screenheight) * 1px * 0.015);
}
.reset-title {
  color: var(--white);
}

.reset {
  border: transparent;
  background-color: var(--white);
  width: 100%;
  height: calc(var(--screenheight) * 1px * 0.055);
  border-radius: calc(var(--screenheight) * 1px * 0.01);
  margin-top: calc(var(--screenheight) * 1px * 0.018);
  font-size: calc(var(--screenheight) * 1px * 0.02);
}

/*******************************************************************/

/*
.about {
  border-radius: calc(var(--screenheight) * 1px * 0.0162);
  height: calc(var(--screenheight) * 1px * 0.15);
  color: var(--white);
  background-color: var(--dark-green);
  padding: calc(var(--screenheight) * 1px * 0.0162);
}

.new-block {
  height: 90%;
  padding-left: calc(var(--screenheight) * 1px * 0.0202);
  padding-right: calc(var(--screenheight) * 1px * 0.0202);
  padding-bottom: calc(var(--screenheight) * 1px * 0.0202);
  padding-top: calc(var(--screenheight) * 1px * 0.0068);
  border-radius: calc(var(--screenheight) * 1px * 0.0202);
  font-size: calc(var(--screenheight) * 1px * 0.017);
  overflow-y: scroll;
}
*/

.right-panel {
  height: calc(var(--screenheight) * 1px * 0.81);
}

.alert-div {
  position: absolute;
}

/*******************************************************************/
.download-wrapper {
  border-radius: calc(var(--screenheight) * 1px * 0.0162);
  height: calc(var(--screenheight) * 1px * 0.15);
  color: var(--white);
  background-color: var(--dark-green);
  display: flex;
  align-items: center;
  justify-content: center;
}

.download {
  border: transparent;
  background-color: var(--medium-brown);
  color: var(--white);
  width: 80%;
  height: calc(var(--screenheight) * 1px * 0.09);
  border-radius: calc(var(--screenheight) * 1px * 0.01);
  font-size: calc(var(--screenheight) * 1px * 0.02);
}

.screenshot {
  position: absolute;
  display: none;
  margin-right: -20px;
}

.chart-title-screenshot {
  text-align: center;
  width: 100%;
  height: calc(var(--screenheight) * 1px * 0.1);
  border: transparent;
  background-color: var(--medium-brown);
  color: var(--white);
  font-size: calc(var(--screenheight) * 1px * 0.025);
  border-radius: calc(var(--screenheight) * 1px * 0.01);
  margin-bottom: calc(var(--screenheight) * 1px * 0.015);
  margin-right: -20px;
}

#screenshot {
  background-color: var(--green);
  margin-right: -20px;
}
